import {
	storyblokEditable,
	StoryblokComponent,
	type SbBlokData,
} from '@storyblok/react'
import { ClientOnly } from 'remix-utils/client-only'
import useAuthenticatedUser from '#app/hooks/use-authenticated-user'
import { type GridColumnsStoryblok } from '#types/component-types-sb'

const GridColumns = ({ blok }: GridColumnsStoryblok) => {
	const numberOfColumns = parseInt(blok.numberOfColumns, 10)
	const user = useAuthenticatedUser()
	const columnGap = blok.columnGap || 'gap-4' // Default gap size if not specified

	let containerClasses = `grid place-items-center ${columnGap} auto-rows-auto	`

	let itemClasses = `flex w-full items-center justify-center h-full`

	const filteredColumns =
		numberOfColumns === -1
			? blok.columns
			: blok.columns.reduce(
					(
						acc: any[],
						column: { component: any; hideForAuthUsers?: boolean },
					) => {
						if (column.component && acc.length < numberOfColumns) {
							if (user && column.hideForAuthUsers) {
								return acc
							}
							acc.push(column)
						}
						return acc
					},
					[],
			  )

	switch (numberOfColumns) {
		case 2:
			containerClasses +=
				' grid-cols-2 md:grid-cols-2 mx-auto place-items-center sm:justify-center md:items-start md:justify-between'
			break
		case 3:
			containerClasses +=
				'  grid md:grid-cols-3 grid-cols-1 mx-auto place-items-center gap-x-4 md:gap-x-6'
			break
		case 4:
			containerClasses +=
				' grid-cols-2 grid-auto-rows-1fr md:grid-cols-2 justify-center lg:grid-cols-4 mx-auto place-items-center md:items-start md:justify-between'
			break
		case 5:
			containerClasses +=
				' md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 mx-auto place-items-center sm:justify-center md:items-start md:justify-between grid-cols-2'
			break
		case 6:
			containerClasses +=
				' md:grid-cols-3 2xl:grid-cols-4 mx-auto place-items-center sm:justify-center md:items-start md:justify-between grid-cols-2'
			break
		case 8:
			containerClasses +=
				' md:grid-cols-4 2xl:grid-cols-8 mx-auto place-items-center sm:justify-center md:items-start md:justify-between grid-cols-2'
			break
		case -1:
			containerClasses +=
				' md:grid-cols-3 2xl:grid-cols-4 grid-cols-1 mx-auto place-items-center gap-x-4 md:gap-x-6'
			break
		default:
			containerClasses += ' grid-cols-1'
			break
	}

	return (
		<ClientOnly fallback={<></>}>
			{() => (
				<ul
					{...storyblokEditable(blok)}
					key={blok._uid}
					className={containerClasses}
				>
					{filteredColumns.map((blokColumn: SbBlokData) => (
						<li
							key={blokColumn._uid}
							className={itemClasses}
							style={{ minWidth: '124px' }}
						>
							<StoryblokComponent blok={blokColumn} />
						</li>
					))}
				</ul>
			)}
		</ClientOnly>
	)
}

export default GridColumns
