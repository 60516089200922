import { storyblokEditable } from '@storyblok/react'
import { type BodyStoryblok } from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
const BodyBlok = ({ blok }: BodyStoryblok) => {
	return (
		<PlentyBody
			{...storyblokEditable(blok)}
			key={blok._uid}
			size={blok.as}
			className={`text-${blok.textColor} font-${blok.weight}`}
		>
			{blok.body}
		</PlentyBody>
	)
}

export default BodyBlok
